import React from "react"
import Masonry from "react-masonry-component"
import PropTypes from "prop-types"
import { defaultPortfolio } from "../../../../defaultData"

const Portfolio = ({
  portfolio = defaultPortfolio,
  showImageInLightBox: setImage,
}) => {
  return (
    <section className="projects  pt48 bg-darker bg-dark">
      <ul className="filters floating cast-shadow mb0"></ul>

      <div className="row " data-gallery-title="Gallery">
        <Masonry>
          {portfolio.map((pic, i) => (
            <div
              className="col-md-3 col-sm-6 masonry-item project"
              data-filter={pic.title}
              key={pic.src}
            >
              <div className="image-tile inner-title hover-reveal text-center">
                <div onClick={() => setImage(i)} key={pic.src} role="none">
                  <img alt={pic.title} src={pic.src} />
                  <div className="title">
                    <h5 className="uppercase mb0">{pic.title}</h5>
                    <span>{pic.subtitle}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      </div>
    </section>
  )
}

Portfolio.propTypes = {
  portfolio: PropTypes.arrayOf(Object),
  setImage: PropTypes.func,
}

export default Portfolio
